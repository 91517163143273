<template>
    <div class="uk-position-relative uk-container uk-container-expand uk-margin-top card-scrollable">
        <div class="uk-flex uk-flex-between uk-margin">
            <div class="uk-flex uk-flex-left">
                <div class="uk-inline">
                    <span class="uk-form-icon uk-form-icon-flip" uk-icon="icon: search"></span>
                    <input class="uk-input" @keyup.enter="(e) => changeMeta(e, 'search')" type="text" placeholder="Search Template...">
                </div>
            </div>
            <div class="uk-flex uk-flex-right">
                <button class="uk-button uk-button-primary uk-border-rounded" type="button" @click="onClickCreate">Create</button>
            </div>
        </div>
        <!-- No Data -->
        <div
            v-if="!isLoading && !meta.search && templateList.totalDocs == 0"
            class="uk-position-absolute uk-position-center uk-flex uk-flex-column uk-flex-center uk-text-center uk-text-italic uk-text-A7A7A7 text-20"
        >
            <div>
                <img :src="`${images}/suitcase-outline.svg`" alt="Suitcase icon" />
            </div>
            <p>
                No templates have been created yet, <br />
                please begin by clicking the <span class="uk-text-weight-600">'Create'</span> button above.
            </p>
        </div>
        <!-- No Search Keyword Matched -->
        <div
            v-else-if="!isLoading && meta.search && templateList.totalDocs == 0"
            class="uk-position-absolute uk-position-center uk-flex uk-flex-column uk-flex-center uk-text-center uk-text-italic uk-text-A7A7A7 text-20"
        >
            <div>
                <img :src="`${images}/search-document-outline.svg`" alt="Search document icon" />
            </div>
            <p class="uk-margin-remove-bottom">No results found!</p>
            <p class="uk-margin-remove-top">Sorry, there is no template to be shown with your search keyword.</p>
            <p>Please try another keyword!</p>
        </div>
        <!-- Data Exist -->
        <div v-else-if="isLoading || templateList.totalDocs > 0">
            <div v-if="meta.search" class="uk-margin-small-bottom uk-text-italic uk-text-A7A7A7">
                The search results for the keyword <span class="uk-text-weight-600">'{{ meta.search }}'</span>
            </div>
            <div class="uk-card uk-card-default">
                <div class="uk-overflow-auto">
                    <table class="uk-table uk-table-middle uk-table-striped uk-text-secondary">
                        <thead>
                            <tr class="uk-text-left">
                                <th class="uk-table-shrink">No</th>
                                <th>WhatsApp Template Title</th>
                                <th class="uk-table-shrink uk-text-center">Action</th>
                            </tr>
                        </thead>
                        <loading-table v-if="isLoading" :colspan="3"/>
                        <tbody v-else>
                            <tr v-for="(data, i) in templateList.docs" :key="i" class="uk-text-left">
                                <td class="uk-text-center">{{ i+1 }}</td>
                                <td>
                                    <template v-if="meta.search">
                                        <div v-html=matchedSearchKeyword(data.title)></div>
                                    </template>
                                    <template v-else>
                                        {{ data.title }}
                                    </template>
                                </td>
                                <td class="uk-text-center">
                                    <button class="uk-button uk-button-small uk-button-default" type="button">Actions</button>
                                    <div uk-dropdown="mode: click">
                                        <ul class="uk-nav uk-dropdown-nav uk-text-left">
                                            <li><a @click="showModal('mainModal', data, true)">Edit</a></li>
                                            <li class="uk-nav-divider"></li>
                                            <li><a @click="showModal('deleteModal', data)">Delete</a></li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <pagination
                    :total-data="templateList.totalDocs"
                    :change-limit="changeLimit"
                    :change-page="changePage"
                />
            </div>
        </div>
        <div id="mainModal" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('mainModal')"></button>
                <div class="uk-modal-header">
                    <h3 v-if="isEdit">Edit Template</h3>
                    <h3 v-else>Create New Template</h3>
                </div>
                <div class="uk-modal-body">
                    <div class="uk-margin">
                        <label class="uk-form-label">Title <b class="uk-text-danger">*</b></label>
                        <input
                            class="uk-input"
                            :class="{'uk-form-danger': errors.has('title')}"
                            name="title"
                            type="text"
                            v-model="form.title"
                            v-validate="'required'"
                            placeholder="Type the Title"
                            :maxlength="30"
                        >
                        <div class="uk-flex uk-flex-between">
                            <span class="uk-text-small uk-text-danger" v-show="errors.has('title')">
                                {{ errors.first('title') }}
                            </span>
                            <span class="uk-text-small uk-margin-auto-left uk-text-868686">
                                {{ form.title.length }}/30 Characters
                            </span>
                        </div>
                    </div>
                    <div class="uk-margin">
                        <div class="uk-form-controls">
                            <label class="uk-form-label">Message <b class="uk-text-danger">*</b></label>
                            <textarea
                                class="uk-textarea"
                                :class="{'uk-form-danger': errors.has('message')}"
                                name="message"
                                type="text"
                                rows="5"
                                v-model="form.message"
                                v-validate="'required'"
                                placeholder="Type the Message"
                                :maxlength="2000"
                                style="resize: vertical;"
                            />
                            <div class="uk-flex uk-flex-between">
                                <span class="uk-text-small uk-text-danger" v-show="errors.has('message')">
                                    {{ errors.first('message') }}
                                </span>
                                <span class="uk-text-small uk-margin-auto-left uk-text-868686">
                                    {{ form.message.length }}/2000 Characters
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-text-right">
                    <button v-if="isActionLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-primary uk-border-rounded uk-text-weight-500" type="button" @click="saveTemplate">Save</button>
                </div>
            </div>
        </div>
        <div id="deleteModal" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('deleteModal')"></button>
                <div class="uk-modal-header uk-padding-remove-horizontal uk-padding-remove-top uk-text-weight-500">Are you sure to delete this template?</div>
                <div class="uk-modal-body uk-padding-remove-horizontal uk-overflow-hidden">
                    <table class="uk-table uk-table-small">
                        <tbody>
                            <tr>
                                <td class="uk-padding-remove uk-text-weight-600">Title </td>
                                <td class="uk-padding-remove-vertical uk-text-center">:</td>
                                <td class="uk-padding-remove uk-width-4-5 uk-table-expand">{{ form.title }}</td>
                            </tr>
                            <tr>
                                <td class="uk-padding-remove uk-text-weight-600">Message </td>
                                <td class="uk-padding-remove-vertical uk-text-center">:</td>
                                <td class="uk-padding-remove uk-width-4-5 uk-table-expand uk-text-break-word">
                                    <div class="pre-wrap">{{ form.message }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="uk-modal-footer uk-text-right uk-padding-remove-horizontal uk-padding-remove-bottom">
                    <button v-if="isActionLoading" class="uk-button uk-button-primary" type="button" disabled><div uk-spinner></div></button>
                    <button v-else class="uk-button uk-button-danger uk-border-rounded uk-text-weight-500" type="button" @click="deleteData">Delete</button>
                </div>
            </div>
        </div>
        <div id="maxTemplateModal" class="uk-flex-top" uk-modal esc-close="false" bg-close="false">
            <div class="uk-modal-dialog uk-modal-body uk-margin-auto-vertical">
                <button class="uk-modal-close-default" type="button" uk-close @click="hideModal('maxTemplateModal')"></button>
                <div class="uk-modal-header uk-flex uk-flex-middle uk-padding-remove-horizontal uk-padding-remove-top">
                    <img :src="`${images}/alert-red-outline.svg`" alt="Icon" class="uk-margin-small-right" />
                    <h3 class="uk-margin-remove">Sorry, you can't add a new template</h3>
                </div>
                <div class="uk-modal-body uk-padding-remove-horizontal uk-overflow-hidden">
                    You've reached the maximum limit of 10 templates.<br/>
                    Please manage your existing templates
                </div>
                <div class="uk-modal-footer uk-text-right uk-padding-remove-horizontal uk-padding-remove-bottom">
                    <button class="uk-button uk-button-primary uk-border-rounded uk-text-weight-500 uk-modal-close" type="button">OK</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import LoadingTable from '@/components/globals/tables/Loading';
import Pagination from '@/components/globals/Pagination';
import { PREFIX_IMAGE } from '@/utils/constant';
import {
    notificationSuccess,
    notificationDanger
} from '@/utils/notification';

export default {
    data(){
        return {
            images: PREFIX_IMAGE,
            meta: {
                limit: 50,
                page: 1,
                search: '',
            },
            form: {
                title: '',
                message: ''
            },
            templateList: { docs: [], totalDocs: 0 },
            isLoading: true,
            isActionLoading: false,
            isEdit: false
        };
    },
    components: {
        Pagination,
        LoadingTable
    },
    watch: {
        meta() {
            this.getInitPageData();
        },
    },
    beforeMount() {
        if(window.UIkit.modal('#mainModal')) window.UIkit.modal('#mainModal').$destroy(true);
        if(window.UIkit.modal('#deleteModal')) window.UIkit.modal('#deleteModal').$destroy(true);
        if(window.UIkit.modal('#maxTemplateModal')) window.UIkit.modal('#maxTemplateModal').$destroy(true);
    },
    async mounted() {
        await this.getInitPageData();
    },
    methods: {
        ...mapActions({
            getWhatsAppTemplateList: 'applicantV4/getWhatsAppTemplateList',
            createWhatsAppTemplate: 'applicantV4/createWhatsAppTemplate',
            updateWhatsAppTemplate: 'applicantV4/updateWhatsAppTemplate',
            deleteWhatsAppTemplate: 'applicantV4/deleteWhatsAppTemplate'
        }),
        async getInitPageData(){
            this.isLoading = true;
            this.templateList = await this.getWhatsAppTemplateList(this.meta);
            this.isLoading = false;
        },
        changeLimit(e) {
            this.meta = {
                ...this.meta,
                limit: e.target.value
            };
        },
        changePage(value) {
            this.meta = {
                ...this.meta,
                page: value
            };
        },
        changeMeta(e, prop) {
            this.meta = {
                ...this.meta,
                [prop]: e.target.value
            };
        },
        resetForm() {
            this.form = {
                title: '',
                message: ''
            };
            this.isEdit = false;
            this.$validator.reset();
        },
        async onClickCreate() {
            if (this.templateList.totalDocs == 10) {
                await window.UIkit.modal('#maxTemplateModal').show();
            } else {
                this.showModal('mainModal');
            }

        },
        async showModal(modal, data=null, edit=false){
            switch (modal) {
            case 'mainModal':
                if (data) {
                    this.form.title = data.title;
                    this.form.message = data.message;
                }
                if (edit) {
                    this.form.id = data._id;
                    this.isEdit = true;
                }
                await window.UIkit.modal('#mainModal').show();
                break;
            case 'deleteModal':
                if (data) {
                    this.form = data;
                }
                await window.UIkit.modal('#deleteModal').show();
                break;
            }
        },
        async hideModal(modal){
            switch (modal) {
            case 'mainModal':
                this.resetForm();
                await window.UIkit.modal('#mainModal').hide();
                break;
            case 'deleteModal':
                this.resetForm();
                await window.UIkit.modal('#deleteModal').hide();
                break;
            }
        },
        async saveTemplate() {
            try {
                this.isActionLoading = true;
                const validate = await this.$validator.validateAll();
                if (!validate || this.$validator.errors.any()) return;

                const response = this.isEdit
                    ? await this.updateWhatsAppTemplate(this.form)
                    : await this.createWhatsAppTemplate(this.form);

                if (response && response.status === 'OK') {
                    notificationSuccess('Saved!');
                    this.getInitPageData();
                    await this.hideModal('mainModal');
                } else {
                    notificationDanger('Failed to save!');
                }
            } catch (err) {
                notificationDanger(err);
            } finally {
                this.isActionLoading = false;
            }
        },
        async deleteData() {
            try {
                this.isActionLoading = true;
                const response = await this.deleteWhatsAppTemplate(this.form._id);
                if (response && response.status === 'OK') {
                    notificationSuccess('Deleted!');
                    this.resetForm();
                    this.getInitPageData();
                    await this.hideModal('deleteModal');
                } else {
                    notificationDanger('Failed to delete!');
                }
            } catch (err) {
                notificationDanger(err);
            } finally {
                this.isActionLoading = false;
            }
        },
        matchedSearchKeyword(title) {
            const regexpVal = new RegExp(this.meta.search, 'gi');
            const matchedKeywordTitle = title.replaceAll(regexpVal, "<span class='uk-text-weight-600'>$&</span>");
            return matchedKeywordTitle;
        }
    },
};
</script>

<style scoped>
.text-20 {
    font-size: 20px;
}

.pre-wrap {
    white-space: pre-wrap;
}
</style>
